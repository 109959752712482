import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  min-height: 100vh;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
`;

export const TermList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const TermItem = styled.li`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
`;

export const Term = styled.strong`
  font-weight: bold;
`;

export const Definition = styled.span`
  color: #555;
  width: 500px;
`;

export const AlphabetNavigation = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

interface LetterButtonProps {
  isActive?: boolean;
}

export const LetterButton = styled.button<LetterButtonProps>`
  background-color: #f0f2f6;
  border: 1px solid #ddd;
  padding: 10px;
  cursor: pointer;
  ${props => props.isActive && `font-weight: bold; background-color: #8a96a3;`}

  &:hover {
    background-color: #ddd;
  }
`;

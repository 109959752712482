import React, { useState } from 'react';
import AtoZNavigation from './navigation';
import {
  Container,
  Definition,
  SearchInput,
  Term,
  TermItem,
  TermList,
} from './styled';

type Term = {
  term: string;
  definition: string;
};

const getGlossaryByLetter = glossary => {
  const glossaryByLetter = {};

  glossary.forEach(item => {
    const firstLetter = item.term[0].toUpperCase();
    if (!glossaryByLetter[firstLetter]) glossaryByLetter[firstLetter] = [];

    glossaryByLetter[firstLetter].push(item);
  });

  return glossaryByLetter;
};

const GlossaryList = ({ glossaryTerms }: { glossaryTerms: Term[] }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredTerms, setFilteredTerms] = useState(glossaryTerms);
  const [activeLetter, setActiveLetter] = useState<string>('ALL');

  const glossaryByLetter = getGlossaryByLetter(glossaryTerms);

  const onLetterClick = (letter: string) => {
    if (letter === 'ALL') setFilteredTerms(glossaryTerms);
    else setFilteredTerms(glossaryByLetter[letter]);

    setActiveLetter(letter);
    setSearchQuery('');
  };

  const filterTerms = (match: string) => {
    const newFiltered = glossaryTerms.filter(
      term =>
        term.term.toLowerCase().includes(match.toLowerCase()) ||
        term.definition.toLowerCase().includes(match.toLowerCase()),
    );

    setActiveLetter('ALL');
    setFilteredTerms(newFiltered);
    setSearchQuery(match);
  };

  return (
    <Container>
      <AtoZNavigation
        activeLetter={activeLetter}
        glossaryByLetter={glossaryByLetter}
        onLetterClick={onLetterClick}
      />
      <SearchInput
        type="text"
        name="searchInput"
        placeholder="Search terms..."
        value={searchQuery}
        onChange={e => filterTerms(e.target.value)}
      />

      <TermList>
        {filteredTerms.map((item, index) => (
          <TermItem key={index}>
            <Term>{item.term}</Term>
            <Definition>{item.definition}</Definition>
          </TermItem>
        ))}
      </TermList>
    </Container>
  );
};

export default GlossaryList;

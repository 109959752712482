import React from 'react';
import { AlphabetNavigation, LetterButton } from './styled';

const AtoZNavigation = ({ onLetterClick, glossaryByLetter, activeLetter }) => {
  const alphabet = Object.keys(glossaryByLetter);

  return (
    <AlphabetNavigation>
      {alphabet.map(letter => (
        <LetterButton
          key={letter}
          type="button"
          isActive={activeLetter === letter}
          onClick={() => onLetterClick(letter)}
        >
          {letter}
        </LetterButton>
      ))}
      <LetterButton
        key="ALL"
        type="button"
        isActive={activeLetter === 'ALL'}
        onClick={() => onLetterClick('ALL')}
      >
        ALL
      </LetterButton>
    </AlphabetNavigation>
  );
};

export default AtoZNavigation;

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-ts": () => import("./../../../src/pages/404.ts" /* webpackChunkName: "component---src-pages-404-ts" */),
  "component---src-pages-about-ts": () => import("./../../../src/pages/about.ts" /* webpackChunkName: "component---src-pages-about-ts" */),
  "component---src-pages-careers-ts": () => import("./../../../src/pages/careers.ts" /* webpackChunkName: "component---src-pages-careers-ts" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-docs-index-ts": () => import("./../../../src/pages/docs/index.ts" /* webpackChunkName: "component---src-pages-docs-index-ts" */),
  "component---src-pages-docs-readme-md": () => import("./../../../src/pages/docs/README.md" /* webpackChunkName: "component---src-pages-docs-readme-md" */),
  "component---src-pages-docs-select-brands-md": () => import("./../../../src/pages/docs/select/brands.md" /* webpackChunkName: "component---src-pages-docs-select-brands-md" */),
  "component---src-pages-docs-select-cards-md": () => import("./../../../src/pages/docs/select/cards.md" /* webpackChunkName: "component---src-pages-docs-select-cards-md" */),
  "component---src-pages-docs-select-getting-started-md": () => import("./../../../src/pages/docs/select/getting-started.md" /* webpackChunkName: "component---src-pages-docs-select-getting-started-md" */),
  "component---src-pages-docs-select-index-md": () => import("./../../../src/pages/docs/select/index.md" /* webpackChunkName: "component---src-pages-docs-select-index-md" */),
  "component---src-pages-docs-select-locations-md": () => import("./../../../src/pages/docs/select/locations.md" /* webpackChunkName: "component---src-pages-docs-select-locations-md" */),
  "component---src-pages-docs-select-offers-md": () => import("./../../../src/pages/docs/select/offers.md" /* webpackChunkName: "component---src-pages-docs-select-offers-md" */),
  "component---src-pages-docs-select-programs-md": () => import("./../../../src/pages/docs/select/programs.md" /* webpackChunkName: "component---src-pages-docs-select-programs-md" */),
  "component---src-pages-docs-select-sdks-android-guide-v-1-md": () => import("./../../../src/pages/docs/select/sdks/android/guide-v1.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-android-guide-v-1-md" */),
  "component---src-pages-docs-select-sdks-android-guide-v-2-md": () => import("./../../../src/pages/docs/select/sdks/android/guide-v2.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-android-guide-v-2-md" */),
  "component---src-pages-docs-select-sdks-android-migration-guide-md": () => import("./../../../src/pages/docs/select/sdks/android/migration-guide.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-android-migration-guide-md" */),
  "component---src-pages-docs-select-sdks-android-reference-v-1-md": () => import("./../../../src/pages/docs/select/sdks/android/reference-v1.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-android-reference-v-1-md" */),
  "component---src-pages-docs-select-sdks-android-reference-v-2-md": () => import("./../../../src/pages/docs/select/sdks/android/reference-v2.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-android-reference-v-2-md" */),
  "component---src-pages-docs-select-sdks-android-releases-md": () => import("./../../../src/pages/docs/select/sdks/android/releases.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-android-releases-md" */),
  "component---src-pages-docs-select-sdks-ios-guide-v-1-md": () => import("./../../../src/pages/docs/select/sdks/ios/guide-v1.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-ios-guide-v-1-md" */),
  "component---src-pages-docs-select-sdks-ios-guide-v-2-md": () => import("./../../../src/pages/docs/select/sdks/ios/guide-v2.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-ios-guide-v-2-md" */),
  "component---src-pages-docs-select-sdks-ios-migration-guide-md": () => import("./../../../src/pages/docs/select/sdks/ios/migration-guide.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-ios-migration-guide-md" */),
  "component---src-pages-docs-select-sdks-ios-reference-v-1-md": () => import("./../../../src/pages/docs/select/sdks/ios/reference-v1.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-ios-reference-v-1-md" */),
  "component---src-pages-docs-select-sdks-ios-reference-v-2-md": () => import("./../../../src/pages/docs/select/sdks/ios/reference-v2.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-ios-reference-v-2-md" */),
  "component---src-pages-docs-select-sdks-ios-releases-md": () => import("./../../../src/pages/docs/select/sdks/ios/releases.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-ios-releases-md" */),
  "component---src-pages-docs-select-sdks-react-native-guide-v-1-md": () => import("./../../../src/pages/docs/select/sdks/react-native/guide-v1.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-react-native-guide-v-1-md" */),
  "component---src-pages-docs-select-sdks-react-native-guide-v-2-md": () => import("./../../../src/pages/docs/select/sdks/react-native/guide-v2.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-react-native-guide-v-2-md" */),
  "component---src-pages-docs-select-sdks-react-native-migration-guide-md": () => import("./../../../src/pages/docs/select/sdks/react-native/migration-guide.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-react-native-migration-guide-md" */),
  "component---src-pages-docs-select-sdks-react-native-reference-v-1-md": () => import("./../../../src/pages/docs/select/sdks/react-native/reference-v1.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-react-native-reference-v-1-md" */),
  "component---src-pages-docs-select-sdks-react-native-reference-v-2-md": () => import("./../../../src/pages/docs/select/sdks/react-native/reference-v2.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-react-native-reference-v-2-md" */),
  "component---src-pages-docs-select-sdks-react-native-releases-md": () => import("./../../../src/pages/docs/select/sdks/react-native/releases.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-react-native-releases-md" */),
  "component---src-pages-docs-select-sdks-security-guidelines-md": () => import("./../../../src/pages/docs/select/sdks/security-guidelines.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-security-guidelines-md" */),
  "component---src-pages-docs-select-sdks-web-v-2-md": () => import("./../../../src/pages/docs/select/sdks/web/v2.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-web-v-2-md" */),
  "component---src-pages-docs-select-sdks-web-v-3-md": () => import("./../../../src/pages/docs/select/sdks/web/v3.md" /* webpackChunkName: "component---src-pages-docs-select-sdks-web-v-3-md" */),
  "component---src-pages-docs-select-transactions-md": () => import("./../../../src/pages/docs/select/transactions.md" /* webpackChunkName: "component---src-pages-docs-select-transactions-md" */),
  "component---src-pages-docs-select-tutorials-card-linking-md": () => import("./../../../src/pages/docs/select/tutorials/card-linking.md" /* webpackChunkName: "component---src-pages-docs-select-tutorials-card-linking-md" */),
  "component---src-pages-docs-select-webhooks-md": () => import("./../../../src/pages/docs/select/webhooks.md" /* webpackChunkName: "component---src-pages-docs-select-webhooks-md" */),
  "component---src-pages-faq-billing-md": () => import("./../../../src/pages/faq/billing.md" /* webpackChunkName: "component---src-pages-faq-billing-md" */),
  "component---src-pages-faq-brands-md": () => import("./../../../src/pages/faq/brands.md" /* webpackChunkName: "component---src-pages-faq-brands-md" */),
  "component---src-pages-faq-cards-select-md": () => import("./../../../src/pages/faq/cards-select.md" /* webpackChunkName: "component---src-pages-faq-cards-select-md" */),
  "component---src-pages-faq-index-ts": () => import("./../../../src/pages/faq/index.ts" /* webpackChunkName: "component---src-pages-faq-index-ts" */),
  "component---src-pages-faq-locations-mo-md": () => import("./../../../src/pages/faq/locations-mo.md" /* webpackChunkName: "component---src-pages-faq-locations-mo-md" */),
  "component---src-pages-faq-marketplace-md": () => import("./../../../src/pages/faq/marketplace.md" /* webpackChunkName: "component---src-pages-faq-marketplace-md" */),
  "component---src-pages-faq-offers-md": () => import("./../../../src/pages/faq/offers.md" /* webpackChunkName: "component---src-pages-faq-offers-md" */),
  "component---src-pages-faq-sandbox-md": () => import("./../../../src/pages/faq/sandbox.md" /* webpackChunkName: "component---src-pages-faq-sandbox-md" */),
  "component---src-pages-faq-transactions-md": () => import("./../../../src/pages/faq/transactions.md" /* webpackChunkName: "component---src-pages-faq-transactions-md" */),
  "component---src-pages-glossary-ts": () => import("./../../../src/pages/glossary.ts" /* webpackChunkName: "component---src-pages-glossary-ts" */),
  "component---src-pages-index-ts": () => import("./../../../src/pages/index.ts" /* webpackChunkName: "component---src-pages-index-ts" */),
  "component---src-pages-legal-dpa-mdx": () => import("./../../../src/pages/legal/dpa.mdx" /* webpackChunkName: "component---src-pages-legal-dpa-mdx" */),
  "component---src-pages-legal-index-ts": () => import("./../../../src/pages/legal/index.ts" /* webpackChunkName: "component---src-pages-legal-index-ts" */),
  "component---src-pages-legal-marketing-and-cookies-mdx": () => import("./../../../src/pages/legal/marketing-and-cookies.mdx" /* webpackChunkName: "component---src-pages-legal-marketing-and-cookies-mdx" */),
  "component---src-pages-legal-merchant-partnership-terms-mdx": () => import("./../../../src/pages/legal/merchant-partnership-terms.mdx" /* webpackChunkName: "component---src-pages-legal-merchant-partnership-terms-mdx" */),
  "component---src-pages-legal-merchant-privacy-mdx": () => import("./../../../src/pages/legal/merchant-privacy.mdx" /* webpackChunkName: "component---src-pages-legal-merchant-privacy-mdx" */),
  "component---src-pages-legal-merchant-terms-mdx": () => import("./../../../src/pages/legal/merchant-terms.mdx" /* webpackChunkName: "component---src-pages-legal-merchant-terms-mdx" */),
  "component---src-pages-legal-offers-marketplace-terms-mdx": () => import("./../../../src/pages/legal/offers-marketplace-terms.mdx" /* webpackChunkName: "component---src-pages-legal-offers-marketplace-terms-mdx" */),
  "component---src-pages-legal-privacy-mdx": () => import("./../../../src/pages/legal/privacy.mdx" /* webpackChunkName: "component---src-pages-legal-privacy-mdx" */),
  "component---src-pages-legal-security-mdx": () => import("./../../../src/pages/legal/security.mdx" /* webpackChunkName: "component---src-pages-legal-security-mdx" */),
  "component---src-pages-legal-terms-select-transactions-north-america-mdx": () => import("./../../../src/pages/legal/terms-select-transactions-north-america.mdx" /* webpackChunkName: "component---src-pages-legal-terms-select-transactions-north-america-mdx" */),
  "component---src-pages-legal-terms-select-transactions-rest-of-world-mdx": () => import("./../../../src/pages/legal/terms-select-transactions-rest-of-world.mdx" /* webpackChunkName: "component---src-pages-legal-terms-select-transactions-rest-of-world-mdx" */),
  "component---src-pages-legal-terms-select-transactions-uk-europe-mdx": () => import("./../../../src/pages/legal/terms-select-transactions-uk-europe.mdx" /* webpackChunkName: "component---src-pages-legal-terms-select-transactions-uk-europe-mdx" */),
  "component---src-pages-press-ts": () => import("./../../../src/pages/press.ts" /* webpackChunkName: "component---src-pages-press-ts" */),
  "component---src-pages-pricing-ts": () => import("./../../../src/pages/pricing.ts" /* webpackChunkName: "component---src-pages-pricing-ts" */),
  "component---src-pages-products-add-ons-tsx": () => import("./../../../src/pages/products/add-ons.tsx" /* webpackChunkName: "component---src-pages-products-add-ons-tsx" */),
  "component---src-pages-products-select-transactions-tsx": () => import("./../../../src/pages/products/select-transactions.tsx" /* webpackChunkName: "component---src-pages-products-select-transactions-tsx" */),
  "component---src-pages-resources-ts": () => import("./../../../src/pages/resources.ts" /* webpackChunkName: "component---src-pages-resources-ts" */),
  "component---src-pages-stories-avios-ts": () => import("./../../../src/pages/stories/avios.ts" /* webpackChunkName: "component---src-pages-stories-avios-ts" */),
  "component---src-pages-stories-index-ts": () => import("./../../../src/pages/stories/index.ts" /* webpackChunkName: "component---src-pages-stories-index-ts" */),
  "component---src-pages-stories-lux-ts": () => import("./../../../src/pages/stories/lux.ts" /* webpackChunkName: "component---src-pages-stories-lux-ts" */),
  "component---src-pages-stories-miconex-ts": () => import("./../../../src/pages/stories/miconex.ts" /* webpackChunkName: "component---src-pages-stories-miconex-ts" */),
  "component---src-pages-stories-perkbox-ts": () => import("./../../../src/pages/stories/perkbox.ts" /* webpackChunkName: "component---src-pages-stories-perkbox-ts" */),
  "component---src-pages-stories-stampfeet-ts": () => import("./../../../src/pages/stories/stampfeet.ts" /* webpackChunkName: "component---src-pages-stories-stampfeet-ts" */),
  "component---src-pages-stories-topcashback-ts": () => import("./../../../src/pages/stories/topcashback.ts" /* webpackChunkName: "component---src-pages-stories-topcashback-ts" */),
  "component---src-pages-use-cases-attribution-and-insights-ts": () => import("./../../../src/pages/use-cases/attribution-and-insights.ts" /* webpackChunkName: "component---src-pages-use-cases-attribution-and-insights-ts" */),
  "component---src-pages-use-cases-rewards-ts": () => import("./../../../src/pages/use-cases/rewards.ts" /* webpackChunkName: "component---src-pages-use-cases-rewards-ts" */),
  "component---src-pages-welcome-ts": () => import("./../../../src/pages/welcome.ts" /* webpackChunkName: "component---src-pages-welcome-ts" */),
  "component---src-templates-blog-author-page-tsx": () => import("./../../../src/templates/blog/author-page.tsx" /* webpackChunkName: "component---src-templates-blog-author-page-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog/index.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog/post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tag-page-tsx": () => import("./../../../src/templates/blog/tag-page.tsx" /* webpackChunkName: "component---src-templates-blog-tag-page-tsx" */)
}

